import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';
import { TreatmentEnum, VisibilityEnum } from '@/utils/amplitude/experiment-constants';
import { Bucket } from '../../lib/makeswift/experiments';
type Props = {
  previewBucket?: Bucket;
  a?: ReactNode;
  b?: ReactNode;
  enableAmplitudeAllocation?: boolean;
  className?: string;
};
export function ExperimentProvider({
  previewBucket,
  a,
  b,
  enableAmplitudeAllocation,
  className
}: Props) {
  // Fetcher function and SWR hook
  const fetcher = (url: string) => fetch(url).then(r => r.json());
  const {
    data,
    error
  } = useSWR('/api/amplitude/experimentprovider-data/', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  });

  // Initial states for parsed data and visibility
  const [displayerValidator, setDisplayerValidator] = useState<Bucket>(Bucket.A);
  const [bucketVisibility, setBucketVisibility] = useState({
    a: VisibilityEnum.Invisible,
    b: VisibilityEnum.Invisible
  });
  useEffect(() => {
    if (error) {
      console.error('Error fetching data', error);
      return;
    }
    if (data) {
      let parsedSwrDataArray: {
        variant: string;
        flagKey: string;
      }[] = [];

      // Get the variant and flagkey for each experiment on the experimentatio cookie
      try {
        data.forEach((experiment: {
          variant: string;
          flagKey: string;
        }) => {
          parsedSwrDataArray.push({
            variant: experiment.variant,
            flagKey: experiment.flagKey
          });
        });

        // Default visibility settings
        let newBucketVisibility = {
          a: VisibilityEnum.Visible,
          b: VisibilityEnum.Invisible
        };
        let newDisplayerValidator: Bucket | undefined = undefined; // Default to Bucket.A

        parsedSwrDataArray.forEach(parsedSwrData => {
          if (parsedSwrData.variant === TreatmentEnum.CONTROL) {
            // Control group logic
            newDisplayerValidator = Bucket.A; // Ensure it can be overridden
          } else if (parsedSwrData.variant === TreatmentEnum.TREATMENT) {
            newDisplayerValidator = Bucket.B;
            newBucketVisibility.a = VisibilityEnum.Invisible;
            newBucketVisibility.b = VisibilityEnum.Visible;
          }
        });
        setDisplayerValidator(newDisplayerValidator || Bucket.A);
        setBucketVisibility(newBucketVisibility);
      } catch (err) {
        console.error('Error processing data', err);
      }
    }
  }, [data, error]);

  // Determine the bucket to display based on allocation settings
  const preview = useMemo(() => enableAmplitudeAllocation ? displayerValidator : previewBucket, [enableAmplitudeAllocation, displayerValidator, previewBucket]);

  // Render the appropriate bucket content
  const content = useMemo(() => {
    if (enableAmplitudeAllocation) {
      let previewContent;
      if (process.env.VERCEL_ENV === 'production') {
        previewContent = <div className={bucketVisibility[preview === Bucket.A ? 'a' : 'b']}>
						{preview === Bucket.A ? a : b}
					</div>;
      } else {
        previewContent = <div className={VisibilityEnum.Visible}>{a}</div>;
      }
      return previewContent;
    } else {
      return <div className={VisibilityEnum.Visible}>{preview === Bucket.A ? a : b}</div>;
    }
  }, [enableAmplitudeAllocation, preview, bucketVisibility, a, b]);
  return <div data-testid="experiment-provider" className={className} data-sentry-component="ExperimentProvider" data-sentry-source-file="ExperimentProvider.tsx">
			{content}
		</div>;
}